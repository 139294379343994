@import "../../../theme.scss";

#truncatedCell {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  cursor: pointer;
  position: relative;
}
#actionCell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 33px;
}
#buttonCell {
  color: $color-blue;
  &:hover {
    cursor: pointer;
  }
}
#scoreDropdown {
    width: 100%;
  .MuiSelect-select {
    color: $color-blue;
    div {
      min-width: 25px;
    }
  }
  svg {
    color: $color-blue;
  }
}
#resolutionCell {
    cursor: pointer;
    color: $color-orange;
  
    &.active {
      color: #00875a;
    }
  
    &.inactive {
      color: unset; 
    }
  }
  #yesNoCell{
    .pulsingText {
        @keyframes pulsate {
            0% {
              opacity: 0.3;
            }
            50% {
              opacity: 1;
            }
            100% {
              opacity: 0.3;
            }
          }
          color: #ff0f0f;
          animation: pulse 1s infinite ease-out;
    }
  }
  .link-cell {
    color: $color-blue;
    cursor: pointer;
    text-decoration: none;
  }
  .copyCell {
    cursor: pointer;
    color: #b3f5ff;
  }
  .tooltipCell {
    .MuiTooltip-tooltip {
        font-size: 1rem;
      }
  }
  .copyCellWrapper {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    svg {
      margin-right: 5px;
      :hover {
        cursor: pointer;
      }
    }
  }
  .cellInput {
    width: auto;
    &:after {
      border: $color-blue;
    }
    &:before {
      border: $color-blue;
    }
    input {
      color: $color-blue;
    }
    textarea {
      color: $color-blue;
    }
    input {
      color: $color-blue;
      &::-webkit-calendar-picker-indicator {
        color: $color-blue;
      }
    }

    label.MuiInputLabel-root {
      color: $color-blue;
    }

    label.Mui-focused {
      color: $color-blue;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid#8899a6;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid #8899a6; // adjust as needed, default is 2px for Material-UI v4
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid #8899a6; // this styles the line when the input is focused
    }
  }