.App {
  text-align: center;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.root::-webkit-scrollbar {
  display: none !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.root {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  scroll-behavior: smooth;
}


.body {
  min-height: 100vh !important;
  scroll-behavior: smooth;
}
