// Breakpoints
$sm: 400px;
$md: 775px;
$lg: 1080px;
$xl: 1440px;
$xxl: 1620px;

@mixin breakpoint($size) {
  @media (min-width: $size) {
    @content;
  }
}

// Colors
$color-blue: #66ccff;
$color-orange: #FFCC66;
$background-color: linear-gradient(0deg, rgba(49, 50, 63, 1) 0%, rgba(49, 50, 63, 1) 70%, rgba(0, 0, 0, 1) 100%);

// Typography
.header {
  font-size: 34px;
  font-weight: 900;
  margin-left: 15px;
  color: white;
}

// Layout Utilities
.flex-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex-row-sb {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.spacer-20 {
  margin-top: 20px;
}

// Material-UI Input Overrides
.dark-input {
  input {
    color: #8899a6;

    &::-webkit-calendar-picker-indicator {
      color: #c270c0;
    }

    &.Mui-disabled {
      -webkit-text-fill-color: rgba(136, 153, 166, 0.6);
    }
  }

  label.MuiInputLabel-root {
    color: #8899a6;
  }

  label.Mui-focused {
    color: #8899a6;
  }

  .MuiInput-underline {
    &:before {
      border-bottom: 1px solid #8899a6;
    }

    &:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid #8899a6;
    }

    &:after {
      border-bottom: 2px solid #8899a6;
    }
  }
}

// Add this to your theme.scss

.custom-switch {
    width: 42px !important;
    height: 26px !important;
    padding: 0 !important;
  
    .MuiSwitch-switchBase {
      padding: 0;
      margin: 2px;
      transition-duration: 300ms;
  
      &.Mui-checked {
        transform: translateX(16px);
        color: #673ab7;
  
        & + .MuiSwitch-track {
          background-color: #00897b;
          opacity: 1;
          border: 0;
        }
  
        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }
  
      &.Mui-focusVisible .MuiSwitch-thumb {
        color: #33cf4d;
        border: 6px solid #fff;
      }
  
      &.Mui-disabled .MuiSwitch-thumb {
        color: $color-blue; // Replace with your SCSS variable for Colors.blue
      }
  
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.3;
      }
    }
  
    .MuiSwitch-thumb {
      box-sizing: border-box;
      width: 22px;
      height: 22px;
    }
  
    .MuiSwitch-track {
      border-radius: 13px; // 26px / 2
      background-color: #39393D;
      opacity: 1;
    }
  }