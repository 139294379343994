@font-face {
  font-family: "Avenir-Bold";
  src: local("sans-serif"), local("sans-serif"),
    url("assets/fonts/AvenirLTStd-Black.otf");
  font-family: "Avenir";
  src: local("sans-serif"), local("sans-serif"),
    url("assets/fonts/AvenirNextLTPro-Regular.otf");
  font-family: "Avenir-Roman";
  src: local("sans-serif"), local("sans-serif"),
    url("assets/fonts/AvenirLTStd-Roman.otf");
  font-family: "Avenir-Condensed";
  src: local("sans-serif"), local("sans-serif"),
    url("assets/fonts/AvenirTStd-Condensed.otf");
  font-display: fallback;
}

body {
  margin: 0;
  font-family: "Avenir", "Avenir-Condensed", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #31323f;
  position: relative;
  min-height: 100vh;
}

body.using-keyboard {
  cursor: none;
}

code {
  font-family: "Avenir", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
