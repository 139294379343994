@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#mobile-tabs {
  .selected-wrapper {
    width: 85px;
    display: flex;
    justify-content: flex-start;
    .mobile-selected {
      color: white;
      font-size: 16px;
      font-weight: 900;
      font-family: "Avenir", "Avenir-Condensed";
      max-width: 95px;
      width: fit-content;
      border-bottom: 4px solid #ffcc66;
    }
  }
  .provider-dropdown {
    position: absolute;
    top: 25px;
    left: 35%;
    background-color: rgb(9, 11, 19);
    border-radius: 4px;
    width: auto;
    min-width: 160px; 
    z-index: 1000;
    animation: fadeIn 0.2s ease-out;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: 10px 0px 20px 0px;
    font-size: 18px;
    .mobile-provider {
      padding-left: 40px;
      font-size: 16px;
      min-width: unset;
      margin-top: 15px;
      color: gray;
      &:hover {
        color: white;
        opacity: 1;
      }
      font-family: "Avenir", "Avenir-Condensed";
      font-weight: 900;
    }
  }
  .icon {
    margin-left: 20px;
  }
}
